import * as React from "react";
import { Fade } from 'react-reveal'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'

import Button from '../components/Button'
import Card from '../components/Card'
import CardExpertises from '../components/CardExpertises'
import Footer from '../components/Footer'
import ListItemVariant from '../components/ListItemVariant'
import Navbar from '../components/Navbar'
import airbusImage from '../assets/images/projects/airbus.webp'
import next from '../assets/next.png'
import react from '../assets/react.png'
import vue from '../assets/vue.png'
import webflow from '../assets/webflow.png'

const DesignPage = () => {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <main className="bg-[url('../assets/images/background-3.webp')] bg-cover bg-no-repeat text-white">
      <Helmet htmlAttributes={{ lang: 'fr-FR' }}>
        <title>
          Code | Ksatra SEM - Fullstack designer freelance à Toulouse
        </title>
        <meta
          name="description"
          content="Construire des projets durables avec des technologies modernes et fiables."
        />
      </Helmet>

      {!isLoading && <Navbar bgColor="bg-black" textColor="text-white" />}

      <div className="flex flex-col gap-24 pt-8 text-white lg:pt-24">
        {/* Jumbo */}
        <div className="px-6 lg:px-32">
          <Fade delay={800}>
            <h3 className="pb-6 text-lg">Code.</h3>
          </Fade>
          <Fade bottom>
            <h1 className="font-serif text-4xl leading-tight text-white lg:text-6xl">
              Construire des projets{' '}
              <span className="font-serif">durables </span> avec des
              technologies
              <span className="font-serif"> modernes </span> et{' '}
              <span className="font-serif">fiables</span>
            </h1>
          </Fade>
        </div>
        <Fade bottom>
          <div className=" px-6 lg:px-32">
            <p className="mb-8 text-gray-200">Votre besoin ?</p>
            <ul className="flex flex-col gap-4 font-serif text-lg">
              <li>Refonte de site web</li>
              <li>Développement d'une application web</li>
              <li>POC / MVP</li>
              <li>Support Front-end</li>
            </ul>
          </div>
        </Fade>
        <Fade bottom>
          <div className="px-6 lg:px-32">
            <div className="mb-8 text-gray-200">Expertises</div>
            <div className="grid items-stretch gap-8 md:grid-cols-3">
              <CardExpertises
                className="row-span-1"
                title="Application web"
                description="Construire un POC, un MVP afin de tester votre idée ou étoffer votre application existante."
                textDescription="text-gray-200"
              />
              <CardExpertises
                className="row-span-1"
                title="Site vitrine"
                description="Promouvoir votre activité et capter des utlisateurs pour le lancement de votre futur produit."
                textDescription="text-gray-200"
              />
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="mx-6 flex flex-col justify-center gap-8 rounded-lg bg-[url('../assets/images/card-cover.svg')] bg-cover bg-no-repeat py-8 lg:mx-32">
            <div>
              <p className="text-center font-serif text-4xl md:text-5xl">
                Mes technologies
              </p>
            </div>
            <div className="flex flex-col justify-center gap-6 px-6 md:flex-row lg:gap-8">
              <ListItemVariant title="React" urlLogo={react} />
              <ListItemVariant title="Next.js" urlLogo={next} />
              <ListItemVariant title="Vue" urlLogo={vue} />
              <ListItemVariant title="Webflow" urlLogo={webflow} />
            </div>
          </div>
        </Fade>

        <Fade bottom>
          <div className="mt-24 px-6 lg:px-32">
            <h2 className="mb-16 text-left font-serif text-4xl md:text-5xl">
              Projets associés
            </h2>
            <div>
              <div className="grid gap-8 md:grid-cols-2">
                <Card
                  description="Conception et développement de la solution Data Product Management"
                  expertises={['UX/UI Design', 'React']}
                  media={airbusImage}
                  mediaAlt="Aperçu projet Airbus"
                  privateProject
                  title="Airbus"
                />
              </div>

              <div className="mt-16 text-center">
                <Link to="/play">
                  <Button title="Découvrir d'autres projets" />
                </Link>
              </div>
            </div>
          </div>
        </Fade>
      </div>

      <Fade bottom>
        <Footer />
      </Fade>
    </main>
  )
}

export default DesignPage
