import React, { FC } from "react";

interface TitleProps {
  className?: string
  title: string
  urlLogo: string
}

const ListItemVariant: FC<TitleProps> = ({ className, title, urlLogo }) => {
  return (
    <div
      className={`flex items-center rounded-md bg-white/10 px-6 py-4 ${className}`}
    >
      <img className="w-12 pr-4" src={urlLogo} />
      <p className={`font-semibold text-white`}>{title}</p>
    </div>
  )
}

export default ListItemVariant;
