import React, { FC } from "react";

interface TitleProps {
  title: string
  description: string
  className: string
  textDescription: string
}

const CardExpertises: FC<TitleProps> = ({
  title,
  description,
  className,
  textDescription,
}) => {
  return (
    <div className={`${className} h-full w-full rounded-sm`}>
      <p className="pb-4 font-serif text-4xl text-white">{title}</p>
      <p className={`${textDescription} text-base leading-8`}>{description}</p>
    </div>
  )
}

export default CardExpertises;
